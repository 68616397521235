
































































































































import { Component, Vue, Model, Prop, Watch, Emit } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import flattenDeep from 'lodash/flattenDeep'
import { createMergeArray } from '@/utils/utils'

@Component
export default class GroupTransfer extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop({ default: '' }) private readonly title!: string
  @Prop({}) private defaultCandidateData!: any
  @Prop({ default: false }) private showTab!: any
  @Prop({ default: true }) private canDelete!: any
  @Prop({ default: false }) private accurateSearch!: any
  @Prop({ default: () => [] }) private defaultSelectedData!: Array<any>

  private searchText = ''
  private candidateData: Array<any> = []
  private selectedData: Array<any> = []
  private tabList: Array<any> = []
  private transferType: any = null
  private type: string = 'headTeacher'
  private changeType({ item, key, keyPath }): void {
    this.type = key
    this.candidateData = cloneDeep(this.defaultCandidateData[key].list)
    this.transferType = this.defaultCandidateData[key].transferType
  }

  private get realShowTab(): any {
    return this.showTab === '' || this.showTab
  }

  public get filteredCandidateData(): Array<any> {
    const data = cloneDeep(this.candidateData) || []
    return this.getFilterData(data)
  }

  private get generateRegExpArr(): any {
    // return new RegExp(`(.*)(${this.searchText.split(' ').join(')(.*)(')})(.*)`, 'ig')
    // return this.searchText.trim().split(' ').map(item => new RegExp(`(.*)(${item})(.*)`, 'i'))
    return this.accurateSearch
      ? [new RegExp(`(.*)(${this.searchText.trim()})(.*)`, 'i')]
      : this.searchText
          .trim()
          .split(' ')
          .map(item => new RegExp(`(.*)(${item.split('').join(')(.*)(')})(.*)`, 'i'))
  }

  // private get generateRegExpReverse(): RegExp {
  //   return new RegExp(`(.*)(${this.searchText.split(' ').reverse().join(')(.*)(')})(.*)`, 'ig')
  // }

  private allMatch(title): boolean {
    // let result = true
    // this.generateRegExpArr.forEach(text => {

    //   if(!text.test(title)){
    //     result = false
    //   }
    // })
    // return result
    return this.generateRegExpArr.every(text => text.test(title))
  }

  private getFilterData(data): any {
    let newData = []
    // const text = this.searchText.toLowerCase().trim()
    const selectedIds = this.selectedData.map(item => item.id)
    switch (this.transferType) {
      case 'doubleGroup':
        newData = data
          .map(group => {
            return {
              ...group,
              children: group.children
                .map(second => ({
                  ...second,
                  // children: second.children.filter(child => child.title.toLowerCase().indexOf(text) !== -1 && !selectedIds.includes(child.id))
                  // children: second.children.filter((child) => (text.test(child.title) || textReverse.test(child.title)) && !selectedIds.includes(child.id)),
                  children: second.children.filter(
                    child => this.allMatch(child.title) && !selectedIds.includes(child.id)
                  ),
                }))
                .filter(group => group.children.length),
            }
          })
          .filter(group => group.children.length)
        break
      case 'group':
        newData = data
          .map(group => {
            return {
              ...group,
              children: (group.children || []).filter(
                // child => child.title.toLowerCase().indexOf(text) !== -1 && !selectedIds.includes(child.id)
                // (child) => (text.test(child.title) || textReverse.test(child.title)) && !selectedIds.includes(child.id)
                child => this.allMatch(child.title) && !selectedIds.includes(child.id)
              ),
              // expand: (this.searchText === '' ? false : true)
            }
          })
          .filter(group => group.children.length)
        break
      default:
        // newData = data.filter(item => item.title.toLowerCase().indexOf(text) !== -1 && !selectedIds.includes(item.id))
        newData = data.filter(item => this.allMatch(item.title) && !selectedIds.includes(item.id))
        break
    }
    return newData
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(newVal): void {
    if (newVal) {
      this.searchText = ''
      this.selectedData = cloneDeep(this.defaultSelectedData)
      this.tabList = []
      if (this.realShowTab) {
        for (let key in this.defaultCandidateData) {
          this.tabList.push({
            key,
            label: this.$t(`common.${key}`),
          })
        }
        this.type = this.tabList[0].key
      }
      const defaultData = this.realShowTab
        ? this.defaultCandidateData[this.tabList[0].key]
        : this.defaultCandidateData
      this.candidateData = cloneDeep(this.realShowTab ? defaultData.list : defaultData) || []
      this.transferType = defaultData.transferType
        ? defaultData.transferType
        : defaultData[0].children
        ? defaultData[0].children[0]?.children
          ? 'doubleGroup'
          : 'group'
        : 'normal'
      const selectedIds = this.selectedData.map(item => item.id)
    }
  }

  @Watch('searchText', { immediate: true })
  private onSearchChange(val): void {
    if (val.trim()) {
      this.candidateData.forEach((item, index) => {
        const result = {
          ...item,
          expand: true,
        }
        this.$set(this.candidateData, index, result)
      })
    } else {
      this.candidateData.forEach((item, index) => {
        const result = {
          ...item,
          expand: false,
        }
        this.$set(this.candidateData, index, result)
      })
    }
  }

  private onEnter(): void {
    switch (this.transferType) {
      case 'doubleGroup':
        this.selectedData.push(this.filteredCandidateData[0].children[0].children[0])
        break
      case 'group':
        this.selectedData.push(this.filteredCandidateData[0].children[0])
        break
      default:
        this.selectedData.push(this.filteredCandidateData[0])
        break
    }
  }

  private expandGroup(groupId): void {
    const index = this.candidateData.findIndex(group => group.id === groupId)
    const data = {
      ...this.candidateData[index],
      expand: !this.candidateData[index].expand,
    }
    this.$set(this.candidateData, index, data)
  }

  private selectGroup(group): void {
    let existIds = this.selectedData.map(item => item.id)
    let mGroup = [] as any
    group.children.forEach(item => {
      createMergeArray(item, mGroup, 'children')
    })
    mGroup.forEach(teacher => {
      if (existIds.includes(teacher.id)) {
        return
      }
      existIds.push(teacher.id)
      this.selectedData.push(teacher)
    })
  }

  private selectItem(item, idx): void {
    this.selectedData.push(item)
  }

  private selectAll(): void {
    this.selectedData = []
    let existIds = [] as any
    if (this.realShowTab) {
      for (let key in this.defaultCandidateData) {
        let filterList = this.getFilterData(this.defaultCandidateData[key].list)
        filterList.forEach(item => {
          if (['group', 'doubleGroup'].includes(this.defaultCandidateData[key].transferType)) {
            let mGroup = [] as any
            item.children.forEach(item => {
              createMergeArray(item, mGroup, 'children')
            })
            mGroup.forEach(teacher => {
              if (existIds.includes(teacher.id)) {
                return
              }
              existIds.push(teacher.id)
              this.selectedData.push(teacher)
            })
          } else {
            if (existIds.includes(item.id)) {
              return
            }
            existIds.push(item.id)
            this.selectedData.push(item)
          }
        })
      }
    } else {
      // this.filteredCandidateData.forEach(item => {
      //   item.children.forEach(teacher => {
      //     if (existIds.includes(teacher.id)) {
      //       return
      //     }
      //     existIds.push(teacher.id)
      //     this.selectedData.push(teacher)
      //   })
      // })
      switch (this.transferType) {
        case 'doubleGroup':
          this.filteredCandidateData.forEach(item => {
            item.children.forEach(it => {
              it.children.forEach(teacher => {
                if (existIds.includes(teacher.id)) {
                  return
                }
                existIds.push(teacher.id)
                this.selectedData.push(teacher)
              })
            })
          })
          break
        case 'group':
          this.filteredCandidateData.forEach(item => {
            item.children.forEach(teacher => {
              if (existIds.includes(teacher.id)) {
                return
              }
              existIds.push(teacher.id)
              this.selectedData.push(teacher)
            })
          })
          break
        default:
          this.filteredCandidateData.forEach(item => {
            if (existIds.includes(item.id)) {
              return
            }
            existIds.push(item.id)
            this.selectedData.push(item)
          })
          break
      }
    }
  }

  private removeAll(): void {
    this.selectedData = []
  }

  private removeItem(id, index): void {
    if (this.canDelete) {
      this.selectedData.splice(index, 1)
    }
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('cancel')
  private onCancel(): boolean {
    this.closeModal()
    return false
  }

  @Emit('confirm')
  private confirm(): Array<any> {
    this.closeModal()
    return this.selectedData
  }
}
